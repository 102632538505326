<template>
<div>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
  <g id="Group_2493" data-name="Group 2493" transform="translate(-532 -334)">
    <circle id="Ellipse_59" data-name="Ellipse 59" cx="11.5" cy="11.5" r="11.5" transform="translate(532 334)" fill="#fff"/>
    <path id="tick" d="M13.862,6.185a1.48,1.48,0,0,0-2.013.575L9.1,11.707,7.527,10.134a1.48,1.48,0,1,0-2.094,2.094l2.961,2.961a1.478,1.478,0,0,0,1.047.435l.205-.015a1.48,1.48,0,0,0,1.089-.747l3.7-6.662a1.48,1.48,0,0,0-.574-2.013Z" transform="translate(535.931 333.016) rotate(11)" :fill="isThemeDark ? '#DFC46B' : 'red'"  />
  </g>
</svg>
</div>

</template>

<script>

export default{
    name:"logout icon",
    data() {
        return{
            isThemeDark: true,
        }
    },
    created() {
        eventBus.$on("theme-change", (data) =>{
            if(data == 'theme-dark'){
                this.isThemeDark = true
            }else{
                this.isThemeDark = false
            }
        })
    },
    mounted() {        
    let currentTheme = localStorage.getItem('theme');
    if(currentTheme == 'theme-light'){
      this.isThemeDark = false
    }else{
      this.isThemeDark = true
    }
    },
}

</script>


<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";

.filllogout{
    fill:$clr-saina-light-dark-main-bg
 }

</style>